.App {
  background-image: url('./Geomatchry Background Login.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  /* in case the image does not load */
  background-color: #8c52ff;
  color: white;
  min-height: 100vh;  
  max-width: 100%;
  size: 100rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 17.5%;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  height: fit-content;
}

.App-name {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: large;
}

.App-name span {
  margin-right: 10px; 
}

.App-name img {
  height: 40px;
  border-radius: 8px;
}

.Nav-buttons {
  display: flex;
  align-items: center;
}

.page-content {
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 10px;
}

.App-link {
  color: #61dafb;
}

.App-title {
  font-size: 48pt;
  font-weight: 700;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.input-span {
  width: 100%
}

.user-text-input {
  min-width: 300px;
  max-width: 500px;
  min-height: 30px;
  max-height: 45px;
  margin: 15px;
  border-radius: 10px;
  padding: 5px;
  font-size: large;
  font-family: sans-serif;
  border-color: white;
  font-weight: 500;
}

.user-button {
  height: 45px;
  width: 75px;
  margin: 15px;
  border-radius: 10px;
  background-color: #7ED957;
  border-color: #7ED957;
  font-size: large;
  font-family: sans-serif;
  font-weight: 600;
  color: white;
}

a {
  color: #7ED957;
  font-weight: 600;
}

.options-span {
  width: 35%;
}

.container {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  align-items: center;
  justify-content: center;
}

.container div {
  display: inline;
  margin: 15px; /* Add space between the elements */
}

.user-checkbox {
  size: 100px;
}

.user-option-desc {
  padding-left: 10px;
  font-size: smaller;
}

.nav-button {
  color: white;
  width: 50px;
  text-decoration: none;
  font-size: x-large;
  margin: 0px 25px;
}

.nav-button:hover {
  color: #7ED957;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.nav-button-active {
  color: #7ED957;
  width: 50px;
  text-decoration: none;
  font-size: x-large;
  margin: 0px 25px;
}

.About-container {
  display: flex;
  flex-direction: column;
  padding: 10px 40px;
  max-width: 100%;
}

.About-container-mobile {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  max-width: 100%;
}

.About-page-entry {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px; /* Adjust spacing between entries */
  align-items: center;
}

.About-page-entry-mobile {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px; /* Adjust spacing between entries */
  align-items: center;
}

.About-page-text {
  flex: 1; /* Text takes up remaining space */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 50px;
  padding: 30px 30px;
}

.About-page-text-mobile {
  flex: 1; /* Text takes up remaining space */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 50px;
  padding: 30px 20px;
}

.About-page-final-text {
  flex: 1; /* Text takes up remaining space */
  min-width: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  color: black;
  border-radius: 50px;
  padding: 30px;
  color: white;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 15px;
}

.About-page-final-text-mobile {
  flex: 1; /* Text takes up remaining space */
  background-color: rgba(0, 0, 0, 0.7);
  color: black;
  border-radius: 50px;
  padding: 30px 20px;
  color: white;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 15px;
}

.About-page-image {
  flex: 1; /* Image takes up remaining space */
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
}

.About-page-image img {
  width: auto;
  height: auto;
  max-width: 750px;
  max-height: 750px;
}

.About-container h1  {
  font-size: 40px;
}

.About-container-mobile h1 {
  font-size: 40px;
  text-align: center;
  margin-top: 10px;
}

.About-container p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}

.About-container-mobile p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}


/* Default styles */
.about-page-desktop {
  display: block; /* Display desktop version by default */
}

.about-page-mobile {
  display: none; /* Hide mobile version by default */
}

.Home-content {
  margin-bottom: 20px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .about-page-desktop {
    display: none; /* Hide desktop version on mobile devices */
  }

  .about-page-mobile {
    display: block; /* Display mobile version on mobile devices */
  }
}

#default {
  background-image: url(./Geomatchry\ Background.png);
  background-position: center;
  background-size: cover;
}

#porange {
  background-image: url(./Porange\ Diagonal.png);
  background-position: center;
  background-size: cover;
}

#blellow {
  background-image: url(./Blellow\ Diagonal.png);
  background-position: center;
  background-size: cover;
}

#retro {
  background-image: url(./Retro\ Diagonal.png);
  background-position: center;
  background-size: cover;
}